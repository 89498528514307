<template>
    <div>
        <v-toolbar height="54" elevation="0" color="primary">
            <div class="d-flex align-center">
                <v-toolbar-title color="white" class="text-caption font-weight-light">{{ title }}</v-toolbar-title>
            </div>

            <v-spacer></v-spacer>
            <v-btn
                @click="$router.push({name: order.status == 'cart' ? 'cart' : 'order', params: {orderUUID: order.uuid}})"
                color="white"
                class="text-capitalize"
                text
                small>
                <v-icon left dark > mdi-keyboard-backspace</v-icon>
                Wróć do panelu
            </v-btn>
     
        </v-toolbar>

        <v-container color="blue-grey lighten-5" v-if="isLoading">
            ładowanie
        </v-container>
        <v-container color="blue-grey lighten-5" v-else>
            <v-snackbar v-model="textUsedSnackbar" timeout="3000" color="primary">
                Treść skopiowana. Prosimy pamiętać, żeby podmienić przykładowe nazwiska na własne ;)

                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="textUsedSnackbar = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        
            <v-row>
                <v-col cols="6"  md="6" xs="12" class="text-left d-flex flex-column" v-for="feature in features" :key="feature._id">
                    <v-card>
                        <v-card-title>{{ feature.name }}</v-card-title>
                        <v-card-subtitle>{{ feature.desc }}</v-card-subtitle>

                        <v-card-text>
                            <v-text-field hide-details dense outlined style="width:300px;" v-if="feature.type == 'text'" v-model="orderPos.details[feature._id]" @input="onChange" />
                            <v-text-field hide-details dense outlined style="width:300px;" v-if="feature.type == 'number'" v-model="orderPos.details[feature._id]" @input="onChange" />
                            <div v-else-if="feature.type == 'select'">
                                <v-select dense outlined style="width:300px;"  v-model="orderPos.details[feature._id]"  :items="feature.options" item-text="name" item-value="name" @input="save"/>
                                <div style="overflow-x: hidden; overflow-y: auto; height: 250px;">
                                    <v-row>
                                        <v-col
                                            v-for="(opt, key) in feature.options" :key=key
                                            class="d-flex child-flex"
                                            cols="4"
                                        >
                                            <v-img
                                                :src="opt.imgUrl"
                                                :lazy-src="opt.imgUrl"
                                                aspect-ratio="1"
                                                class="grey lighten-2"
                                            >   
                                                <span class="ml-1">{{opt.name}}</span>
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >   
                                                   
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </v-col>
                                    </v-row>

                                </div>
                            </div>
                        </v-card-text>

                        <div v-if="feature.helperImageUrl" style="overflow-x: hidden; overflow-y: auto; height: 278px;">
                            <v-img :src="feature.helperImageUrl"></v-img>
                        </div>
                        
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import Api from '../../CustomerApi';

let timeout = null;

export default {
    name: 'ProductCard',
    params: {
    },
    components: {
    },

    data: () => ({
        product:{texts:""},
        radios:null,
        tab: null,
        textUsedSnackbar: false,

        isLoading: true,
        title: "Trwa ładowanie",
        order: null,
        orderPos: null,
        features: []
    //
    }),
    async created () {
        this.isLoading = true;
        let orderUUID = this.$route.params.orderUUID;
        if (!orderUUID) return this.$router.push({ name: 'cart' });

        let cartPosId = this.$route.params.cartPosId;
        if (!cartPosId) return this.$router.push({ name: 'cart' });

        try {
            Api.setToken(orderUUID);
            this.order = await Api.order.get();
            let orderContents = await Api.order.getContent();
            this.orderPos = orderContents.find(op => op._id == cartPosId);
            if (!this.orderPos || !this.orderPos.details) return this.$router.push({ name: 'cart' });

            this.features = await Api.features.search({_id: Object.keys(this.orderPos.details)});
        } catch (error) {
            return this.$router.push({ name: 'cart' });
        }

        this.title = "Produkt: " + this.orderPos.name;

        
        this.isLoading = false;
    },
    methods: {
        onChange() {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => this.save(), 600);
        },

        async save() {
            await Api.order.updateContent(this.orderPos._id, {details: this.orderPos.details});
            this.$toast.success("Zmiana zapisana");
        }
    }
};
</script>
<style lang="scss" scoped>
.list-group-item {
    border-radius: 0px;
}

.radio {
    .coverFont {
        background-color: #979797;
    }
    .insideFont {
        background-color: #b67b9f;
    }

}
.ui-check input:checked + i::before {
    
    background-color: #1d1a23;
}
.label {
    border-radius: 0px;
    padding: .4em .5em;
}
.blue-grey {
    background-color: #979797;

}
</style>